// config.js

const bizIcon = 'https://assets.cdn.filesafe.space/3ecCzxMIvHmFrjpuSE7Z/media/6750aab7b2399027632ac9f3.png';
const myBizIcon = 'https://mybizsolutions.us/static/media/icon-app-logo.83ff8bf39a11df9fb7ac.jpg';
const imageOne = "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/3ecCzxMIvHmFrjpuSE7Z/media/6750bf7ae74a49f657444694.jpeg";
const imageTwo = "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/3ecCzxMIvHmFrjpuSE7Z/media/6750bf4787f9920216e301a4.webp";
const imageThree = "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/3ecCzxMIvHmFrjpuSE7Z/media/6750bfaffc9c3355daede2fa.jpeg";
const imageFour = "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/3ecCzxMIvHmFrjpuSE7Z/media/6750bf4787f992801de301a5.jpeg";
const imageFive = "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/3ecCzxMIvHmFrjpuSE7Z/media/6750c054846fe51a30c1eec5.png";

const redirectLink = "https://padillascleaning.com/";

export const siteConfig = {
  title: "Padilla's Cleaning Services LLC",
  metaDescription: "Padilla's Cleaning Services LLC",
  theme: {
    primaryColor: "blue-600",
    secondaryColor: "gray-900",
    accentColor: "text-blue-600"
  },
  logo: {
    src: bizIcon,
    alt: "Padilla's Cleaning Services LLC Icon",
  },
  navigation: [
    { name: "Services", sectionId: "services" },
    { name: "About", sectionId: "about" },
    { name: "Location", sectionId: "location" },
  ],
  businessDetails: {
    name: "Padilla's Cleaning Services LLC",
    headline: "Expert Cleaning Services for Tampa, Temple Terrace, Brandon, and Surrounding Areas",
    address: "1819 French Creek Rd, Tampa, FL, 33613",
    description: `
        Padilla's Cleaning Services LLC is your trusted partner for residential and commercial cleaning in Tampa, Temple Terrace, Brandon, and nearby communities. We take pride in delivering spotless results, using eco-friendly products and advanced cleaning techniques to ensure a healthier and more inviting space for your home or business. From routine cleanings to deep sanitizations, trust us to handle all your cleaning needs with professionalism and care.
    `,
  },
  images: {
    business: {
      src: imageOne,
      alt: "Expert Cleaning Services for Tampa, Temple Terrace, Brandon, and Surrounding Areas",
      width: 2432,
      height: 1442
    }
  },
  features: [
    {
        description: 
          "Eco-Friendly and Safe: We prioritize your safety by using non-toxic, eco-friendly cleaning products that are safe for your family, pets, and the environment. Trust our team to handle your belongings with utmost care.",
      },
    {
        description: 
          "Flexible and Reliable Service: Whether you're home or away, we offer flexible scheduling to fit your lifestyle. Our trusted and insured cleaners ensure a hassle-free experience, leaving your space spotless.",
      },
    {
        description: 
          "Tailored Cleaning Solutions: From standard cleanings to deep sanitization, we customize our services to meet your unique needs. We provide time estimates during booking to help you plan your day effortlessly.",
      },
  ],
  services: {
    sectionTitle: "Our Services",
    description: 
      "Padilla's Cleaning Services LLC offers a wide range of professional cleaning solutions designed to create spotless, inviting spaces. Whether you need routine cleaning, deep sanitization, or carpet care, our services are tailored to meet your unique needs.",
    callouts: [
      {
        name: 'House Cleaning',
        description: 
          "From deep-cleaning every corner to leaving surfaces spotless and refreshed, our house cleaning services bring a sense of luxury and comfort to your home.",
        imageSrc: imageTwo,
        imageAlt: 'Padilla’s Cleaning Services team performing house cleaning in Tampa, FL.',
      },
      {
        name: 'Interior Cleaning',
        description: 
          "Our interior cleaning services focus on restoring cleanliness and order to every corner of your space, enhancing its comfort and reflecting your personal style.",
        imageSrc: imageThree,
        imageAlt: 'Interior cleaning in progress by Padilla’s Cleaning Services in Tampa, FL.',
      },
      {
        name: 'Carpet Cleaning',
        description: 
          "Our goal is to deliver effective and thorough carpet cleaning solutions that enhance comfort and maintain the beauty of your home, leaving carpets fresh and inviting.",
        imageSrc: imageFour,
        imageAlt: 'Padilla’s Cleaning Services team providing professional carpet cleaning in Tampa, FL.',
      },
    ],
  },
  about: {
    sectionTitle: "About",
    description:   
      "Padilla's Cleaning Services LLC is your reliable partner for professional cleaning solutions in Tampa, FL, and surrounding areas. With a commitment to excellence, we specialize in residential and commercial cleaning services, including house cleaning, carpet care, and interior detailing. Our fully licensed and insured team is dedicated to creating spotless, inviting spaces tailored to your needs. At Padilla's Cleaning Services LLC, we turn your vision of a clean and welcoming environment into reality. Let us bring comfort and cleanliness to your space today.",
    image: {
      src: imageFive,
      alt: "Padilla's Cleaning Services team delivering high-quality cleaning services in Tampa, FL.",
      width: 1200,
      height: 800
    }
  },
  location: {
    sectionTitle: "Our Location",
    destinationAddress: "1819 French Creek Rd, Tampa, FL, 33613",
    googleMapsEmbedUrl: 
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3468.5328464875377!2d-82.47243238489379!3d28.076364982637012!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c2c9ef45901377%3A0xabcdef123456789!2s1819%20French%20Creek%20Rd%2C%20Tampa%2C%20FL%2033613!5e0!3m2!1sen!2sus!4v1710407450828!5m2!1sen!2sus",
    subCategories: [
      { 
        name: 'Apple Maps', 
        url: 'https://maps.apple.com/?address=1819%20French%20Creek%20Rd,%20Tampa,%20FL,%2033613&ll=28.076365,-82.472432&q=1819%20French%20Creek%20Rd',
      },
      { 
        name: 'Bing Maps', 
        url: 'https://www.bing.com/maps?q=1819+French+Creek+Rd,+Tampa,+FL+33613&FORM=HDRSC6',
      },
      { 
        name: 'Google Earth', 
        url: 'https://earth.google.com/web/search/1819+French+Creek+Rd,+Tampa,+FL,+33613/@28.076365,-82.472432,15a,814.57589625d,35y,0h,0t,0r',
      },
      { 
        name: 'Google Maps', 
        url: 'https://www.google.com/maps/place/1819+French+Creek+Rd,+Tampa,+FL+33613/@28.076365,-82.472432,15z',
      }
    ]
  },  
  contact: {
    sectionTitle: "Get in Touch",
    description: 
      "At Padilla's Cleaning Services LLC, we’re here to provide top-quality cleaning services for your home or business in Tampa, FL, and surrounding areas. Whether you have questions, need a free quote, or want to schedule a service, our dedicated team is ready to assist. Contact us today for a spotless experience!",
    socialLinks: {
      facebook: redirectLink,     
      instagram: redirectLink,     
      twitter: redirectLink,     
      linkedin: redirectLink,     
      youtube: redirectLink,     
    },
    contactNumbers: [
      "Mobile: (819) 539-6529",
    ],
    logo: bizIcon,
    emailRecipient: "info@padillascleaning.com"
  },
  footer: {
    bizSolutionsLogo: myBizIcon
  }
};


